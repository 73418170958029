import Category from "../components/products/Category"
import { useLoaderData, Await } from "react-router-dom"
import { Suspense } from "react"
import LoadingWrapper from "../components/common/LoadingWrapper"

const CategoriesDetails = () => {
    const data = useLoaderData() 

    return <Suspense fallback={<LoadingWrapper text=""/>}>
        {/* Preparing your personal restaurant! Please wait */}
        <Await resolve={data.data}>
            {() => <>
                <Category />
            </>}        
        </Await>
    </Suspense>
}

export default CategoriesDetails