import { useDispatch, useSelector } from "react-redux"
import Option from "./Option"
import ProductModifiers from "./ProductModifiers"
import { productDetailsActions } from "../../../store/productDetails"
import classes from './Options.module.css'
import { useEffect, useState } from "react"

const selectTheFirstItem = (arr, skip) => {
    if (!skip) {
        arr[0] = {...arr[0], itemSelected: true}
    }
    
    if (arr[0].modifiers) {
        arr[0].modifiers.forEach(mod => {
            if (mod.mandatory) {
                selectTheFirstItem(mod.options)
            } else {
                selectTheFirstItem(mod.options, true)
            } 
        })
    }
}

const removeTheFirstItem = (arr) => {
    arr[0] = {...arr[0], itemSelected: false}
    if (arr[0].modifiers) {
        arr[0].modifiers.forEach(mod => {
            if (mod.mandatory) {
                selectTheFirstItem(mod.options)
            }
        })
    }
}
const removeAllSelectedItems = (arr) => {
    arr.forEach(item => {
        if (item.options) {
            item.options.forEach(option => {
                if (!option.itemSelected) {
                    if (option.modifiers) {
                        option.modifiers.forEach(mod => {
                            mod.options.forEach(suboption => {suboption.itemSelected = false})                            
                        })
                        removeAllSelectedItems(option.modifiers)
                    }
                }
            })
        }
    })
}
function searchForId(dataToSearch, parentModifierID, optionID) {
    let foundIndex = dataToSearch.findIndex(x => x.id === parentModifierID); 
    removeAllSelectedItems(dataToSearch)   
    dataToSearch.forEach(item => item.itemSelected = false)
    if (foundIndex >=0 ) {
        dataToSearch[foundIndex].options.forEach(f => {
            if (f.id === optionID) {
                f.itemSelected = true
                if (f.modifiers) {
                    f.modifiers.forEach(m => {
                        if (m.mandatory) {
                            selectTheFirstItem(m.options)
                        } else {
                            selectTheFirstItem(m.options, true)
                        }
                    })
                }
            } else {
                f.itemSelected = false
            }
        })
    } else {        
        dataToSearch.forEach(element => {
            element.options.forEach(option => {
                if (option.modifiers) {
                    searchForId(option.modifiers, parentModifierID, optionID)                   
                }
            });
        });
    }

    return dataToSearch
}

function searchForIdNotRequired(dataToSearch, parentModifierID, optionID) {
    let foundIndex = dataToSearch.findIndex(x => x.id === parentModifierID);    
    removeAllSelectedItems(dataToSearch)
    dataToSearch.forEach(item => item.itemSelected = false)
    if (foundIndex >=0 ) {
        dataToSearch[foundIndex].options.forEach(f => {
            if (f.id === optionID) {
                if (f.itemSelected === false) {
                    console.log('is allready selected')
                    f.itemSelected = true
                    if (f.modifiers) {
                        f.modifiers.forEach(m => {
                            selectTheFirstItem(m.options)
                        })
                    }
                } else {
                    f.itemSelected = false
                    console.log('is not selected')
                    if (f.modifiers) {
                        f.modifiers.forEach(m => {
                            removeTheFirstItem(m.options)
                        })
                    }
                }                
            } 
        })
    } else {        
        dataToSearch.forEach(element => {
            element.options.forEach(option => {
                if (option.modifiers) {
                    searchForIdNotRequired(option.modifiers, parentModifierID, optionID)                   
                }
            });
        });
    }
    
    return dataToSearch
}


const Options = ({options, parentModifier}) => {
    const dispatch = useDispatch()
    const productDetails = useSelector(state => state.productDetails.data)
    const [limitReached, setLimitReached] = useState(false)


    const setOptionHandler = (id) => {
        let data = JSON.parse(JSON.stringify(productDetails));
        // console.log('parentModifier.mandatory::: ', parentModifier)
        if (parentModifier.mandatory || parentModifier.description === "Add a soda?" || parentModifier.description === "Add a beverage?") {
            const modifiedData = searchForId(data, parentModifier.id, id)
            dispatch(productDetailsActions.createProduct(modifiedData))
        } else {
            console.log('id:::: ', id)
            const modifiedDataNotRequired = searchForIdNotRequired(data, parentModifier.id, id)
            dispatch(productDetailsActions.createProduct(modifiedDataNotRequired))
        }        
        
    }

    useEffect(() => {
        if (parentModifier.maxselects) {
            const selectedOptions = options.filter(option => option.itemSelected)
            if (selectedOptions.length >= parentModifier.maxselects) {
                setLimitReached(true)
            } else {
                setLimitReached(false)
            }
        }
    }, [options])



    return <>
        {options && <div className={`${classes.options_container} ${parentModifier.use_images ? 'grid-5' : 'grid-4'}`}>
            {options.map(option => (
                <Option key={option.id} option={option} setOption={setOptionHandler} limitReached={limitReached} choiceStyle={parentModifier.use_images}/>
            ))}    
        </div>}

        {options.filter(option => option.itemSelected).map(item => (
            <div key={item.id}>{item.modifiers && item.modifiers.length > 0 && <ProductModifiers modifiers={item.modifiers}/>}</div>
        ))}
    </>
}

export default Options