import pinIcon from '../../assets/pin_v2.svg'
import pinIconCobranded from '../../assets/pin_cobranded.svg'
import classes from './Map.module.css'
import { useEffect } from 'react';
let map;
function initMap(markers) {
    var mapOptions = {
        zoom: 17,
        // mapTypeId: google.maps.MapTypeId.ROADMAP,
        center: new window.google.maps.LatLng(markers[0].address.latitude, markers[0].address.longitude),
    };
    map = new window.google.maps.Map(document.getElementById("map"), mapOptions);

    

    map.setOptions({ styles: 
        [
        {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#444444"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#f2f2f2"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "lightness": 45
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#46bcec"
                },
                {
                    "visibility": "on"
                }
            ]
        }
    ] 
        
        
    });

    createMarkers(markers);
    showMarkers();
}
let gMarkers =[]
function createMarkers(markers) {
    var infowindow = new window.google.maps.InfoWindow();

    markers.forEach((item) => {

        var marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(
                parseFloat(item.address.latitude),
                parseFloat(item.address.longitude)
            ),
            icon: item.cobranded ? pinIconCobranded : pinIcon,
        });
        gMarkers.push(marker);
        
        window.google.maps.event.addListener(
            marker,
            "click",
            (function (marker) {
                return function () {
                    gMarkers.forEach(item => {
                        item.setIcon(pinIcon);
                    });
                    marker.setIcon(pinIcon);      

                    // map.setZoom(15);
                    map.setCenter(marker.getPosition());
                    infowindow.setContent(item.name);
                    infowindow.open(map, marker);
                };
            })(marker)
        );
    });
    // for (let i = 0; i < markers.length; i++) {
    //     for (let j = 0; j < gMarkers.length; j++) {
    //         gMarkers[j].markerID = 'item-' + markers[j].id            
    //     }
        
    // }
}
function hideMarkers() {
    setMapOnAll(null);
    gMarkers = [];
}
function showMarkers() {
    setMapOnAll(map);
}
function setMapOnAll(map) {
      
    for (let i = 0; i < gMarkers.length; i++) {
        gMarkers[i].setMap(map);
    }
       
}

function compareDistance( a, b ) {
    if ( a.distance < b.distance ){
      return -1;
    }
    if ( a.distance > b.distance ){
      return 1;
    }
    return 0;
}

const MapV2 = ({locations}) => {
    useEffect(() => {

        initMap(locations)
    })
    return <div className={classes.google_map}>
        <div id='map'></div>
    </div>
}

export default MapV2