import { useRef, useState } from "react"
import { registerUser } from "../../scripts/fetchApi";
import { useDispatch } from "react-redux";
import { variousActions } from "../../store/various";
import { formatPhone, isEmail, isEmpty, isSame } from "../../scripts/helpers";
import { authActions } from "../../store/auth";
import { getuserInfo, getCartById } from "../../scripts/fetchApi";
import { getItemFromSessionStorage, getItemFromStorage, setSessionStorageItem, setStorageItem } from "../../scripts/localStorage";

import classes from './Authentication.module.css'


const Signup = ({position}) => {
    const dispatch = useDispatch()
    const [signUpSuccess, setSignUpSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)


    const emailInputRef = useRef(null)
    const phoneInputRef = useRef(null)
    const firstNameInputRef = useRef(null)
    const lastNameInputRef = useRef(null)
    const passwordInputRef = useRef(null)
    const confirmedPasswordInputRef = useRef(null)

   

    const [formInputsValidity, setFormInputsValidity] = useState({
        email: true,
        phone: true,
        firstName: true,
        lastName: true,
        password: true,
        matchPassword: true
    })

    const signUpHandler = async (event) => {
        event.preventDefault()
        

        const enteredEmail = emailInputRef.current.value
        const enteredPhone = phoneInputRef.current.value
        const enteredFirstName = firstNameInputRef.current.value
        const enteredLastName = lastNameInputRef.current.value
        const enteredPassword = passwordInputRef.current.value
        const enteredConfirmedPassword = confirmedPasswordInputRef.current.value


        const enteredEmailisValid = !isEmpty(enteredEmail) && isEmail(enteredEmail)
        const enteredPhoneIsValid = !isEmpty(enteredPhone)
        const enteredFirstNameIsValid = !isEmpty(enteredFirstName)
        const enteredLastNameIsValid = !isEmpty(enteredLastName)

        const enteredPasswordisValid = !isEmpty(enteredPassword) && !isEmpty(enteredConfirmedPassword)

        const passwordMatch = isSame(enteredPassword, enteredConfirmedPassword)

        setFormInputsValidity({
            email: enteredEmailisValid,
            phone: enteredPhoneIsValid,
            firstName: enteredFirstNameIsValid,
            lastName: enteredLastNameIsValid,
            password: enteredPasswordisValid,
            matchPassword: passwordMatch
        })

        const formIsValid = enteredEmailisValid && enteredPhoneIsValid && enteredFirstNameIsValid && enteredLastNameIsValid && enteredPasswordisValid && passwordMatch

        if (formIsValid) {
            setIsLoading(true)
            const reqData = {
                email: enteredEmail,
                phone: enteredPhone,
                first_name: enteredFirstName,
                last_name: enteredLastName,
                password: enteredPassword
            }

            // const cart = getItemFromStorage('currentCart')
            const cart = getItemFromSessionStorage('currentCart')
            const login = await registerUser(reqData)

            if (login.message) {
                dispatch(variousActions.setError(login))
            } else {


                dispatch(authActions.login(login))
                const user = await getuserInfo(login.authtoken)
                dispatch(authActions.setUser(user))
                if (cart) {
                    const getCart = await getCartById(cart.id, user.authtoken) // this apparently gets the current cartand if it exists it adds it to the logged in user? TODO / TOCHECK

                    if (getCart.id) {
                        // setStorageItem('currentCart', getCart)
                        setSessionStorageItem('currentCart', getCart)
                    }
                }
                
                setSignUpSuccess(true)
            }
            setIsLoading(false)
        } 

    }

    const [phoneValue, setPhoneValue] = useState('')

    const phoneFormatHandler = (e) => {    
        setPhoneValue(formatPhone(e.target.value))
    }

    let cartClass = ''
    if (position && position === 'cart') {
        cartClass = 'cart_footer_button'
    }

    return <>
    <div className="margin-bottom-30">
            <h3 className={classes.form_title}>
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/></svg>
                Join Rewards
            </h3>
        </div>
        {!signUpSuccess && <form onSubmit={signUpHandler} className='form-fields-container'>       
            <div className="form-controller">
                <label className="label" htmlFor="email_input">Email</label>
                <input type="email" id="email_input" placeholder="Email" required ref={emailInputRef} className={`${!formInputsValidity.email ? 'error' : null}`} />
                {!formInputsValidity.email && <div className='error-message'>Please enter a valid email!</div>}
            </div>
            <div className="form-controller">
                <label className="label" htmlFor="phone_input">Phone</label>
                <input type="text" id="phone_input" value={phoneValue} placeholder="Phone" required ref={phoneInputRef} className={`${!formInputsValidity.phone ? 'error' : null}`} onChange={phoneFormatHandler}  /> 
                {!formInputsValidity.phone && <div className='error-message'>Please enter a phone number!</div>}
            </div>
            <div className="form-controller-group">
                <div className="form-controller">
                    <label className="label" htmlFor="first_name_input">First Name</label>
                    <input type="text" id="first_name_input" placeholder="First Name" required ref={firstNameInputRef} className={`${!formInputsValidity.firstName ? 'error' : null}`} />
                    {!formInputsValidity.firstName && <div className='error-message'>Please enter a name!</div>}
                </div>
                <div className="form-controller">
                    <label className="label" htmlFor="last_name_input">Last Name</label>
                    <input type="text" id="last_name_input" placeholder="Last Name" required ref={lastNameInputRef} className={`${!formInputsValidity.lastName ? 'error' : null}`} />
                    {!formInputsValidity.lastName && <div className='error-message'>Please enter a name!</div>}
                </div>
            </div>
            <div className="form-controller-group">
                <div className="form-controller">
                    <label className="label" htmlFor="password_input">Password</label>
                    <input id="password_input" type="password" required minLength={8} ref={passwordInputRef} className={`${!formInputsValidity.password ? 'error' : null}`} />
                    {!formInputsValidity.password && <div className='error-message'>Please enter a password!</div>}
                </div>
                <div className="form-controller">
                    <label className="label" htmlFor="confirm_password_input">Confirm Password</label>
                    <input id="confirm_password_input" type="password" minLength={8} required ref={confirmedPasswordInputRef} className={`${!formInputsValidity.matchPassword ? 'error' : null}`}/>
                    {!formInputsValidity.matchPassword && <div className='error-message'>Passwords don not match!</div>}
                </div>
            </div>
            <div className={`form-controller ${cartClass}`}>
                {!isLoading && <button className="btn" type="submit">Sign up</button>}
                {isLoading && <div className="btn"><span className="loader"></span></div>}
            </div>
        </form>}
        {signUpSuccess && <>
            <h3>Welcome!</h3>
            <p>Account created successfully!</p>
        </>}
    </>
}

export default Signup