import { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addCartCoupon, removeCartCoupon } from "../../scripts/fetchApi"
import { variousActions } from "../../store/various"
import { isEmpty } from "../../scripts/helpers"
import { setSessionStorageItem, setStorageItem } from "../../scripts/localStorage"
import { cartActions } from "../../store/cart"

import classes from './AlternativePayment.module.css'

const PromoCode = () => {
    const dispatch = useDispatch()
    const promoCodeRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isTouched, setIsTouched] = useState(false)
    const [isLoadingRemove, setIsLoadingRemove] = useState(false)
    // const [showDrawer, setShowDrawer] = useState(false)
    const cart = useSelector(state => state.cart.cart)

    const applyCodeHandler = async () => {
        setIsLoading(true)
        const applyCoupon = await addCartCoupon(cart.id, {
            coupon_code: promoCodeRef.current.value
        })

        if (applyCoupon.id) {
            // setStorageItem('currentCart', applyCoupon)
            setSessionStorageItem('currentCart', applyCoupon)
            dispatch(cartActions.addCart(applyCoupon))
            dispatch(variousActions.setError({
                message: 'Coupon successfully added to cart!',
                type: "success"
            }))
        } else {
            dispatch(variousActions.setError(applyCoupon))
        }
        setIsLoading(false)
    }

    const inputHandleChange = (event) => {
        if (!isEmpty(event.target.value)) {
            setIsTouched(true)
        } else {
            setIsTouched(false)
        }
    }

    const removeCouponHandler = async () => {
        setIsLoadingRemove(true)
        const removeCoupon = await removeCartCoupon(cart.id)

        if (removeCoupon.id) {
            // setStorageItem('currentCart', removeCoupon)
            setSessionStorageItem('currentCart', removeCoupon)
            dispatch(cartActions.addCart(removeCoupon))
            dispatch(variousActions.setError({
                message: 'Coupon successfully removed from the cart!',
                type: "success"
            }))
            setIsTouched(false)
        } else {
            dispatch(variousActions.setError(removeCoupon))
        }
        setIsLoadingRemove(false)
        
    }


    return <div className={classes.drawer}>
        
        {cart.coupon && cart.coupon.couponcode && <>
            <h4 className={classes.rewards_header_title}>Applied Promo Code</h4>
            <div className={classes.applied_coupons}>
                <p>{cart.coupon.couponcode}</p>
                {!isLoadingRemove && <button onClick={removeCouponHandler} title="remove coupon">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                    <span className="sr-only">Remove coupon</span>
                </button>}
                {isLoadingRemove && <div className={classes.btn_placeholder}><span className="loader dark-raspberry"></span></div>}
            </div>
        </>}
        {!cart.coupon && <>
            <h4 className={classes.rewards_header_title}>Add Promo Code</h4>
            <div className={`form-fields-container ${classes.apply_promo_container}`}>
                <div className="form-controller-3">
                    <label className="sr-only" htmlFor="promo_code">Input promo code</label>
                    <input id="promo_code" ref={promoCodeRef} type="text" placeholder="Enter Code" onChange={inputHandleChange}/>
                </div>
                {!isLoading && <button className={`btn ${classes.apply_promo_button}`} onClick={applyCodeHandler} disabled={!isTouched ? true : false}>Apply</button>}
                {isLoading && <div className={`btn ${classes.apply_promo_button}`}><span className="loader dark"></span></div>}     
            </div>
        </>}
    </div>
    
}

export default PromoCode