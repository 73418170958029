import { useState } from 'react'
import classes from './BuilderImage.module.css'
import IndividualImageBuilder from "./IndividualImageBuilder"

const ImagesContainer = ({wholeImage}) => {
    const [loading, setLoading] = useState(false)
    // const [loadedImages, setLoadedImages] = useState([])

    const imagesLoadedHandler = (e) => {
        console.log('wholeImage::: ', wholeImage)
        
    }
    // const arr = []
    // const onLoad = (imgIndex) => {
    //     arr.push(imgIndex)
    //     if (arr.length === wholeImage.length) {
    //         setLoading(false)
    //     } else {
    //         setLoading(true)
    //     }
    // }
    // console.log('aaaaaaaaaaaaaaaaaa')
    
    return <>
        {loading && <div className={classes.loader_container}><span className='loader dark'></span></div>}
        <div className={`${classes.container_static} ${loading ? classes.container_loading : ''}`}>
            {wholeImage && wholeImage.length > 0 && wholeImage.map((img, i) => (
                <div key={img.uri + Math.random()} className={classes.image_container_item}>                
                    {/* <IndividualImageBuilder img={img} imagesAreLoaded={imagesLoadedHandler} imgIndex={i}/> */}
                    <img src={img.uri} alt="" style={{filter: img.filter ? img.filter : ''}} />
                    {/* onLoad={() => onLoad(i)} */}
                </div>
            ))}
        </div>
    </>
}

export default ImagesContainer