
// import { useState } from "react"
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux"
// import { userLogout } from "../../scripts/fetchApi";
// import { getAuthToken } from "../../scripts/auth";
// import { variousActions } from "../../store/various";
// import { cartActions } from "../../store/cart";
import { authActions } from "../../store/auth";

import classes from './Header.module.css'
import SignInIcon from "../../icons/singinicon";

const HeaderSignIn = () => {
    const dispatch = useDispatch()
    // const navigate = useNavigate()
    const isAuth = useSelector(state => state.auth.user)

    // const [logoutLoading, setLogoutLoading] = useState(false)

    const user = useSelector(state => state.auth.user)

    const openSignInSidebar = () => {
        // setSignInSidebar(prevState => prevState = !prevState)
        dispatch(authActions.setUserSideBarState())
    }


    return <>
        {!isAuth && <button className="btn-clear" onClick={openSignInSidebar}>
            <div className={classes.control_box}>
                <SignInIcon />
                <div className={classes.text_block}>
                    <span>Sweet rewards</span>
                    Sign In/Join
                </div>
            </div>
        </button>}
        {isAuth && <div className={classes.control_box}>
            <div className={`${classes.text_block} ${classes.text_block_sign_in}`}>
                <Link to='/account'>
                    <div className={classes.user_logged_in}>
                        <SignInIcon />
                        <div>
                            <span>{user.first_name}</span>
                            {/* <div className='small'>go to account</div> */}
                        </div>
                        
                    </div>
                    <div className={classes.account_helper_text}>My Account</div>
                </Link>

            </div>
            
        </div>}
        
    </>
}

export default HeaderSignIn