import { useDispatch, useSelector } from 'react-redux';

import classes from './PickupPannel.module.css'
import { useEffect, useRef, useState } from 'react';
import Location from './Location';

import { useGetLocation, getDistanceFromLatLonInKm } from '../../hooks/hooks';

import { getLocationsByLatLng, getLocationsByLatLngWithDate } from '../../scripts/fetchApi';
import { variousActions } from '../../store/various';

import { locationActions } from '../../store/location';


import MapV2 from './Mapv2';
import LocationPin from '../../icons/locationpin';
import SearchIcon from '../../icons/searchicon';
import NoLocationIcon from '../../icons/nolocationicon';


const PickupPannel = () => {
    const dispatch = useDispatch()
    const [foundLocations, setFoundLocations] = useState([])
    const [loading, setLoading] = useState(false);
    const [currentLoading, setCurrentLoading] = useState(false);

    const [flag, setFlag] = useState(false)

    const inputRef = useRef(null);

    const getLocation = useGetLocation();


    const [noLocation, setNoLocation] = useState(false)
    const [inputError, setInputError] = useState(false)
    const [showMap, setShowMap] = useState(false)

    let orderType = useSelector(state => state.location.order)

    let vehicleInfo = useSelector(state => state.cart.vehicleInfo)

    
    const [locationBlocked, setLocationBlocked] = useState(false)

    const [isCurbside, setIsCurbside] = useState(false)

    useEffect(() => {
        if (orderType.type === 'schedule') {
            if (orderType.date !== null && orderType.time !== null) {
                setLocationBlocked(false)
                if (isCurbside) {
                    setLocationBlocked(true)
                    if (vehicleInfo.vehicleColor && vehicleInfo.vehicleMake && vehicleInfo.vehicleModel) {
                        setLocationBlocked(false)
                    }
                    // if (vehicleInfo.vehicleColor !== '' && vehicleInfo.vehicleMake !== '' && vehicleInfo.vehicleModel !== '' && orderType.date !== null && orderType.time !== null) {
                    //     setLocationBlocked(false)
                    // }
                }
            } else {
                setLocationBlocked(true)
                if (isCurbside) {
                    setLocationBlocked(true)
                    if (vehicleInfo.vehicleColor && vehicleInfo.vehicleMake && vehicleInfo.vehicleModel) {
                        setLocationBlocked(false)
                    }
                    // if (vehicleInfo.vehicleColor !== '' && vehicleInfo.vehicleMake !== '' && vehicleInfo.vehicleModel !== '' && orderType.date !== null && orderType.time !== null) {
                    //     setLocationBlocked(false)
                    // }
                }
            }
            
        } else {
            setLocationBlocked(false)
            if (isCurbside) {
                setLocationBlocked(true)
                if (vehicleInfo.vehicleColor && vehicleInfo.vehicleMake && vehicleInfo.vehicleModel) {
                    setLocationBlocked(false)
                }
                // if (vehicleInfo.vehicleColor !== '' && vehicleInfo.vehicleMake !== '' && vehicleInfo.vehicleModel !== '') {
                //     setLocationBlocked(false)
                // }
            }
        }

        
    }, [orderType, vehicleInfo, isCurbside])

    // const changeToCurbside = (e) => {
    //     setIsCurbside(e)
    // }

    const [searchedLocations, setSearchedLocation] = useState('')
    const searchLocationHandler = async () => { 
        setLoading(true)               
        if (inputRef.current.value === '') {
            setInputError(true)
            setLoading(false)
            setShowMap(false)
        } else {   
            const inputAddress = inputRef.current.value; 
            setSearchedLocation(inputAddress)
            const geocoder = new window.google.maps.Geocoder();
            let lat = "";
            let lng = "";
            const geo = async () => {

                await geocoder.geocode(
                    {
                        // address: "inputAddress " + inputAddress,
                        address: inputAddress,
                        componentRestrictions: {
                            country: "US",
                        },
                    },
                    function (results, status) {
                        
                        if (status == window.google.maps.GeocoderStatus.OK) {

                            lat = results[0].geometry.location.lat();
                            lng = results[0].geometry.location.lng();
                        } else {
                            setNoLocation(true)
                            return;
                        }
                    }
                );

                return { lat: lat, lng: lng };
            };

            (async () => {
                var latLng = await geo();
                
                let loc
                let type = isCurbside ? 'curbside' : 'pickup'
                
                if (orderType.type === 'asap') {                    
                    loc = await getLocationsByLatLng(latLng, type)
                } else {
                    loc = await getLocationsByLatLngWithDate(latLng, type, orderType.date, orderType.time)
                }
                if (loc.length > 0) {
                    loc.forEach(element => {
                        element.distanceFromSearch = (getDistanceFromLatLonInKm(element.address.latitude, element.address.longitude, latLng.lat, latLng.lng) * 0.621371).toFixed(2)
                    });
                    setFoundLocations(loc)
                    setShowMap(true)
                    setLoading(false)
                } else {
                    setNoLocation(true)
                    setLoading(false)
                    setShowMap(false)
                }
            })();
            
            setNoLocation(false)
            setInputError(false)
            
        }
    }
    
  

    const getCurrentLocationHandler = async () => {   
        setCurrentLoading(true)  
        if (getLocation.lat) {
            let loc
            let type = isCurbside ? 'curbside' : 'pickup'
            
            if (orderType.type === 'asap') {
                loc = await getLocationsByLatLng(getLocation, type)
            } else {
                loc = await getLocationsByLatLngWithDate(getLocation, type, orderType.date, orderType.time)
            }
            if (loc.length > 0) {
                loc.forEach(element => {
                    element.distanceFromSearch = (getDistanceFromLatLonInKm(element.address.latitude, element.address.longitude, getLocation.lat, getLocation.lng) * 0.621371).toFixed(2)
                });
                setFoundLocations(loc)
                setShowMap(true)
                setCurrentLoading(false)
            } else {
                setFoundLocations([])
                setNoLocation(true)
                setCurrentLoading(false)
                setShowMap(false)
            }
            
        } else {
    
            if (getLocation.message && getLocation.message === "denied") {
                dispatch(variousActions.setError({message: 'Please allow browser location'}))
            } else {
                dispatch(variousActions.setError({message: 'Something went wrong, please try again'}))
            }
            setCurrentLoading(false)  
        }
    }


    
    useEffect(() => {
        let autocomplete;
        
        autocomplete = new window.google.maps.places.Autocomplete((inputRef.current), {
            types: ['geocode'],
            componentRestrictions: {country: "us"} 
        });

        window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
            autocomplete.getPlace();   
                     
        });
    }, [flag])



    const changeLocation = () => {
        setFoundLocations([])
        setNoLocation(false)
        setShowMap(false)
        setFlag(prevState => prevState = !prevState)
        dispatch(locationActions.setOrderType('asap'))
    }
    
    
    return <>   
        {foundLocations.length === 0 && <div className='form-fields-container'>
            {/* <DateTime /> */}
            {/* <Curbside curbside={changeToCurbside}/> */}
            <div className={`form-controller ${locationBlocked && 'invalid'}`}>                              
                <button className={`btn-underline btn-underline-red`} onClick={getCurrentLocationHandler}>
                    {currentLoading && <span className="loader dark-raspberry"></span>}                    
                    {!currentLoading && <LocationPin />}
                    <span>Use my location</span>
                </button>
                <label className='label' htmlFor='searchAddress'>Enter an address</label>
                <div className={classes.form_input_container}>
                    <input id='searchAddress' type='text' placeholder='City, State or Zip Code' ref={inputRef} className={`${inputError ? 'error' : ''}`} required />
                    
                    
                    <button onClick={searchLocationHandler} className={classes.btn_simple}>
                        {loading && <span className="loader"></span>}
                        {!loading && <SearchIcon />}
                        <span className='sr-only'>Search location</span>
                    </button>
                </div>
                {inputError && <div className='error-message'>please fill in the field</div>}
            </div>
        </div>}
        {noLocation && <div className={classes.no_location_found}>
            <NoLocationIcon /> 
            <div className={classes.no_location_found_text}>There are no locations within 50 miles of this address</div>  
        </div>}
        {/* {showMap && <Map locations={foundLocations} zoomLevel={15}/>} */}
        {!noLocation && <>
            <div>
                {foundLocations.length > 0 && <>
                    {/* <div className={classes.info}>{isCurbside ? 'Curbside pickup' : 'Pickup'} <span>{orderType.type === 'schedule' ? `${(new Date(orderType.date + 'T' + orderType.time).toLocaleString('en-US'))}` : 'ASAP'}</span></div>             */}
                    <p className={classes.current_location_address}>Locations near <span>{searchedLocations}</span></p>
                    <button className={`btn-underline btn-underline-red btn-underline-static`} onClick={changeLocation}>
                        <LocationPin />
                        <span>Change Location</span>
                    </button>
                </>}
                {showMap && <MapV2 locations={foundLocations} zoomLevel={15}/>}
            </div>

            {foundLocations.length > 0 && <div className={classes.location_container}>
                {foundLocations.map((item, index) => (
                    <Location key={item.id} itemIndex={index} data={item} deliveryType={isCurbside ? 'curbside' : 'pickup'}/> 
                ))}
            </div>}
        </>}
        

        
    </>
}

export default PickupPannel