import { useState, useRef } from "react";
import { useNavigate  } from "react-router-dom";
import { useDispatch } from "react-redux";
import { cartSetDeliveryMethod, createCart, getMenuFromLocationId } from "../../scripts/fetchApi";
import { variousActions } from "../../store/various";
import { deleteItemFromSessionnStorage, deleteItemFromStorage, setSessionStorageItem, setStorageItem } from "../../scripts/localStorage";
import { cartActions } from "../../store/cart";
import { virtualCartActions } from "../../store/virtualCart";
import { locationActions } from "../../store/location";
import DeliveryIcon from "../../icons/deliveryicon";
import ClockIcon from '../../icons/clockicon';
import LocationPin from "../../icons/locationpin";

import classes from './DeliveryPannel.module.css'

const DeliverySetDetailsSimplyfied = ({orderType, searchedLocations, foundLocation, deliveryType, time}) => {
    const navigate = useNavigate();
    const dispatch =  useDispatch()
    const [locationLoading, setLocationLoading] = useState(false);
    const buildingRef = useRef(null)
    const specialInstructionsRef = useRef(null)

    const fetchData = async (cartMethod) => {
        const menu = await getMenuFromLocationId(foundLocation.id)
        //first delete cart
        // deleteItemFromStorage('currentCart')
        deleteItemFromSessionnStorage('currentCart')
        dispatch(cartActions.addCart(null))
        dispatch(virtualCartActions.clearVirtualCart())
        dispatch(locationActions.setLocationItem(foundLocation))
        dispatch(locationActions.setMenuItem(menu))
        dispatch(locationActions.setLocationsSideBarState())
        dispatch(locationActions.setVirtualDeliveryType(deliveryType))
        setStorageItem('currentLocationId', {
            currentLocationId: foundLocation.id,
            currentLocationSlug: foundLocation.slug
        }, null) 
        setStorageItem('currentLocation', {
            currentLocation: foundLocation,
            deliveryType: deliveryType,
            time: time
        }, null) 
        setStorageItem('currentMenu', {
            menu: menu
        }, null) 

        dispatch(cartActions.addCart(cartMethod))
        // setStorageItem('currentCart', cartMethod)
        setSessionStorageItem('currentCart', cartMethod)
        
        navigate(`/${menu.slug}/menu`);
                
    } 

    const startDeliveryHandle = async (e) => {
        e.preventDefault()
        setLocationLoading(true)
        const data = foundLocation


        const newCart = await createCart({restaurant_id: data.id})


        if (newCart.id) {
            const deliveryCartInfo = {
                delivery_mode: 'delivery',
                time: time.type,
                schedule_date: time.date,
                schedule_time: time.time,
                street_address: searchedLocations.street_number + ' ' + searchedLocations.street,
                city: searchedLocations.city,
                zip_code: searchedLocations.postal_code,
            } 
            if (buildingRef.current.value) {
                deliveryCartInfo.building = buildingRef.current.value
            }
            if (specialInstructionsRef.current.value) {
                deliveryCartInfo.special_instructions = specialInstructionsRef.current.value
            }          
            //set delivery method
            const cartMethod = await cartSetDeliveryMethod(newCart.id, deliveryCartInfo)
            if (cartMethod.message) {
                dispatch(variousActions.setError(cartMethod))
            } else {
                fetchData(cartMethod)
            }
            
        } else {
            dispatch(variousActions.setError(newCart))
        }
        setLocationLoading(false)
    }
    return <>
        <form onSubmit={startDeliveryHandle}>
            <div className={`${classes.text_info} ${classes.text_info_header}`}>
                <DeliveryIcon />

                <h4>Sweeeeet!</h4>
                <p>We deliver treats  straight to your door!</p>
                {orderType.type === 'scheduled' && <div className={classes.text_info_item}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.9471 11.088C24.0219 1.83751 13.1071 -3.09563 5.56278 2.12578C5.33076 2.0268 5.05494 2.04356 4.81153 2.26571C3.63339 3.30483 2.57296 4.49441 1.76384 5.84888C-3.2418 13.5575 3.08976 24.6648 12.181 24.6343C13.4098 24.7639 19.5978 23.8625 19.1649 22.2934C19.0147 22.0261 18.675 21.9323 18.4088 22.0844C15.9049 23.5159 12.8949 24.1143 10.0669 23.4502C2.85627 21.9487 -1.32957 12.7785 2.93038 6.57077C3.69895 5.36732 4.69787 4.31504 5.81389 3.40725C6.01189 3.24063 6.08576 3.03635 6.07508 2.83795C6.45837 2.588 6.85665 2.35324 7.25899 2.1562C8.82402 1.35432 10.5837 0.927033 12.3424 0.92597C17.7622 0.895138 22.3505 5.02989 23.2996 10.295C23.8786 13.2221 23.1958 16.1786 21.8584 18.8286C21.5908 19.2736 22.2862 19.7295 22.5826 19.2862C24.3174 16.9542 25.2374 13.9991 24.9471 11.088Z" fill="#8D0E57"/>
                        <path d="M17.899 11.8316C17.2874 11.8511 16.674 11.8611 16.0588 11.8741C15.7192 11.9337 14.3679 11.8945 13.0456 11.9055C13.1086 11.0714 13.195 10.2421 13.2455 9.5511C13.3769 7.88066 13.4707 6.19048 13.421 4.50946C13.4028 3.44913 11.7294 3.61236 11.9089 4.65178C12.0735 5.63019 12.1479 6.66207 12.1796 7.66332C12.2079 9.07814 12.1825 10.5049 12.1119 11.9243C10.9953 11.9635 10.1021 12.0715 10.1777 12.355C10.078 12.8707 11.4374 12.8028 12.0598 12.8361C12.0519 12.9599 12.0446 13.0838 12.036 13.2074C12.0102 13.6713 11.9248 14.1297 11.8911 14.592C11.9455 15.3152 13.0771 15.2083 13.0013 14.4917C12.9602 14.0229 12.9609 13.4875 12.984 12.9288C14.5835 13.0664 16.3055 13.2432 17.8684 13.3501C18.9045 13.4338 18.9344 11.7898 17.899 11.8316Z" fill="#8D0E57"/>
                    </svg>
                    <div className={classes.text_info_item_text}>
                        <div className={classes.text_info_item_header}>Delivery Time</div>
                        <span>{(new Date(orderType.date + 'T' + orderType.time).toLocaleString('en-US'))}</span>
                    </div>
                </div>}
                {orderType.type === 'asap' && <div className={classes.text_info_item}>
                    <ClockIcon />
                    <div className={classes.text_info_item_text}>
                        <div className={classes.text_info_item_header}>Delivery Time</div>
                        <span>ASAP</span>
                    </div>
                </div>}
                <div className={classes.text_info_item}>
                    <LocationPin />     
                    <div className={classes.text_info_item_text}>
                        <div className={classes.text_info_item_header}>Delivery Address</div>
                        <span>{searchedLocations.full_address}</span>
                    </div>
                </div>
                <div className={`form-fields-container ${classes.form_fields_container}`}>        
                    <div className='form-controller'>
                        <label htmlFor='locationDetails' className="label">Apt/Suite/Flr (Optional)</label>
                        <input ref={buildingRef} type='text' id='locationDetails' name='locationDetails' placeholder='Apt/Suite/Flr (Optional)'/>
                    </div>
                    {/* <div className='form-controller'>
                        <label className='sr-only' htmlFor='locationDetails'>Location details</label>
                        <input type='text' id='locationDetails' name='locationDetails' placeholder='Apt/Suite/Flr (Optional)'/>
                    </div> */}
                    <div className='form-controller'>
                        <label className='label' htmlFor='locationInstructions'>Special Instructions (Optional)</label>
                        <textarea ref={specialInstructionsRef} type='text' id='locationInstructions' name='locationInstructions' placeholder='Special Instructions (Optional)'/>
                    </div>
                </div>
            
            </div>
            <div className={classes.cta_container}>
                {!locationLoading && <button className='btn btn-100' type="submit">Start Delivery Order</button>}
                {locationLoading && <div className="btn"><span className="loader"></span></div>}
            </div>
        </form>
    </>
}

export default DeliverySetDetailsSimplyfied 