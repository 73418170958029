import { defer, useLoaderData, Await } from "react-router-dom"
import { getMenuNoId } from "../scripts/fetchApi"
import { getItemFromStorage, setStorageItem } from "../scripts/localStorage"
import { redirect  } from "react-router-dom";
import { Suspense } from "react"
import SuperCategories from "../components/products/SuperCategories"
import LoadingWrapper from "../components/common/LoadingWrapper";
import Bannerv2 from "../components/common/Bannerv2";
import { GACTreatwareRedirect } from "../scripts/helpers";
import BannerSimple from "../components/common/BannerSimple";


const Home = () => {    
    const data = useLoaderData() 
    return <Suspense fallback={<LoadingWrapper text=""/>}>
        {/* Please wait while fetching cookies and ice cream! */}
        <Await resolve={data.data}>
            {() => <>
                {/* <Banner /> */}
                {/* <Bannerv2 noLocation={true}/> */}
                <div className="overflow_container">
                    <BannerSimple />
                    <SuperCategories />
                </div>
            </>}
        </Await>
    </Suspense>
}

export default Home


export async function loader(params) {

    const storage = getItemFromStorage('currentLocation')
    const locationId = getItemFromStorage('currentLocationId')
    // console.log('home page')
    if (storage === null && !locationId) {
        console.log('no storage')
        // console.log('home get default menu because no location')
        const fetchLocation = async () => {
            const menu = await getMenuNoId()            
            setStorageItem('currentMenu', {
                menu: menu
            }, 3600) 

            return menu
        }

        const menu = fetchLocation()

        return defer({
            data: menu
        })
    } else if (locationId) { //there is no locatioon set, but there is a location id
        //GAC Treatware functionality
        console.log('locationId:::: ', locationId)
        // GACTreatwareRedirect(storage.currentLocation.data, locationId.currentLocationSlug)

        if (locationId.currentLocationSlug) {
            return redirect(`/${locationId.currentLocationSlug}/menu`)
        }
    } else {
        //GAC Treatware functionality
        // GACTreatwareRedirect(storage.currentLocation.data, storage.currentLocation.slug)


        // console.log('home redirect to location page')
        return redirect(`/${storage.currentLocation.slug}/menu`)
    }
}