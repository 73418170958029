import { useEffect, useRef } from 'react'

import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../../store/auth';
import { locationActions } from '../../store/location';
import { getAuthToken } from '../../scripts/auth';
import { cartActions } from '../../store/cart';
import { getItemFromSessionStorage, getItemFromStorage } from '../../scripts/localStorage';

import classes from './Header.module.css'

import logoCoBranded from '../../assets/FATB_GACMSC_Logo_D.svg'
import logoCoBrandedMobile from '../../assets/FATB_GACMSC_Logo_M.svg'
import logoGAC from '../../assets/logo-gac.svg'
import logoMSC from '../../assets/logo-marble.svg'

import { Link } from 'react-router-dom';
import { useMediaQuery } from '../../hooks/hooks';
import HeaderCartButton from './HeaderCartButton';
import HeaderLocationDesktop from './HeaderLocationDesktop';
import HeaderSignIn from './HeaderSignIn';
import HeaderLocationMobile from './HeaderLocationMobile';

import SidebarContainer from './SidebarContainer';
import SidebarAuthContainer from './SidebarAuthContainer';
import SidebarLocationContainer from './SidebarLocationContainer';
import HeaderWrapper from './HeaderWrapper';

const Header = () => {
    const dispatch = useDispatch()
    const screen768andlarger = useMediaQuery('md');  
    const storage = getItemFromStorage('currentLocation')
    // const storageCart = getItemFromStorage('currentCart')
    const storageCart = getItemFromSessionStorage('currentCart')
    const cart = useSelector(state => state.cart.cart)

    // useEffect(() => {
    //     // const token = getAuthToken();
    //     const token = sessionStorage.getItem('token')
    //     if (!token) {
    //         dispatch(authActions.logout())
    //     } else if (token === 'EXPIRED') {
    //         dispatch(authActions.logout())
    //     } else {
    //         dispatch(authActions.login({authtoken: token}))
    //     }
    //     if (storage) {
    //         dispatch(locationActions.setLocationItem(storage.currentLocation))
    //         // dispatch(locationActions.setMenuItem(menu))
    //     }
    //     if (!cart && storageCart) {
    //         dispatch(cartActions.addCart(storageCart))
    //     }
    // }, [dispatch])

    

    let locationStore = useSelector(state => state.location.location)


    // const isLocationSidebarOpened = useSelector(state => state.location.islocationSidebarOpened)
    // const isUserSidebarOpened = useSelector(state => state.auth.isUserSidebarOpened)
    // const openCartSidebar = useSelector(state => state.cart.cartSidebarOpen)
    

    const openLocationSidebar = () => {
        dispatch(locationActions.setLocationsSideBarState())   
        if (cart) {
            if (cart.deliverymode === 'pickup' || cart.deliverymode === 'curbside') {
                dispatch(locationActions.setVirtualDeliveryType('pickup')) 
            }
            if (cart.deliverymode === 'dispatch') {
                dispatch(locationActions.setVirtualDeliveryType('delivery'))
            }
        }  else {
            dispatch(locationActions.setVirtualDeliveryType('pickup'))
        }       
        
    }
    
    // const openSignInSidebar = () => {
    //     // setSignInSidebar(prevState => prevState = !prevState)
    //     dispatch(authActions.setUserSideBarState())
    // }

    // const openCartSidebarHandler = () => {
    //     dispatch(cartActions.setCartSidebarState())
    // }

    // const closeCartSidebarHandler = () => {
    //     setTimeout(() => {
    //         dispatch(cartActions.setCartSidebarState())
    //     }, 400)
    // }

    // const cartSidebar = useRef(null)

    return <>
        <HeaderWrapper>
            <div className="container-full">
                <div className={classes.header_inner}>
                    <div className={classes.logo}>
                        {!locationStore && <Link to='/'>
                            {/* <img src={logoCoBranded} alt='logo' className={classes.logo_cobranded}/> */}
                                {screen768andlarger && <img src={logoCoBranded} alt='logo' className={classes.logo_cobranded} width={154} height={66}/>}
                                {!screen768andlarger && <img src={logoCoBrandedMobile} alt='logo' className={classes.logo_cobranded}/>}
                            </Link>}
                        {locationStore && locationStore.id && <Link to={`/${locationStore.slug}/menu`}>
                            {locationStore.cobranded && <>
                                {screen768andlarger && <img src={logoCoBranded} alt='logo' className={classes.logo_cobranded} width={154} height={66}/>}
                                {!screen768andlarger && <img src={logoCoBrandedMobile} alt='logo' className={classes.logo_cobranded}/>}
                            </>}
                            {!locationStore.cobranded && locationStore.data.attributes.includes("MSC") && <img src={logoMSC} alt='logo' className={classes.logo_msc}/>}
                            {!locationStore.cobranded && !locationStore.data.attributes.includes("MSC") && <img src={logoGAC} alt='logo' className={classes.logo_gac}/>}
                        </Link>}
                    </div>

                    <div className={classes.header_controls}>
                        {screen768andlarger && <div className={classes.header_controls_item}>
                            {!locationStore && <button className={`btn ${classes.btn_location}`} onClick={openLocationSidebar} title='Find you store'>Start Your Order</button>}
                            {/* btn-radiate  */}
                            {locationStore && locationStore.id && <HeaderLocationDesktop />}
                        </div>}
                        
                        <div className={`${classes.header_controls_item} ${classes.header_controls_item_sign_in}`}>
                            <HeaderSignIn />
                        </div>
                        <div className={classes.header_controls_item}>
                            <HeaderCartButton />
                        </div>

                    </div>
                </div>
            </div>
            
            {!screen768andlarger && <HeaderLocationMobile />}

            {/* {isLocationSidebarOpened && <>
                {ReactDOM.createPortal(
                    <Backdrop hide={openLocationSidebar} />,
                    document.getElementById("backdrop-root")
                )}
                {ReactDOM.createPortal(
                    <Sidebar click={openLocationSidebar}>
                        <SearchLocation closeSidebar={openLocationSidebar} />
                    </Sidebar>,
                    document.getElementById("overlay-root")
                )}
            </>}

            {isUserSidebarOpened && <>
                {ReactDOM.createPortal(
                    <Backdrop hide={openSignInSidebar} />,
                    document.getElementById("backdrop-root")
                )}
                {ReactDOM.createPortal(
                    <Sidebar click={openSignInSidebar}>
                        <Authentication closeSidebar={openSignInSidebar} />
                    </Sidebar>,
                    document.getElementById("overlay-root")
                )}
            </>}

            {openCartSidebar && <>
                {ReactDOM.createPortal(
                    <Backdrop hide={openCartSidebarHandler} />,
                    document.getElementById("backdrop-root")
                )}
                {ReactDOM.createPortal(
                    <Sidebar click={openCartSidebarHandler} activeState={openCartSidebar}>
                        <Cart />
                    </Sidebar>,
                    document.getElementById("overlay-root")
                )}
            </>} */}
            <SidebarLocationContainer />
            <SidebarAuthContainer />
            <SidebarContainer />
            
        </HeaderWrapper>
        
    </>
    
}

export default Header;