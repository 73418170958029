import { useParams, Link } from "react-router-dom"
import classes from './OrderStatusComponent.module.css'
import { formatAndSplitDates, formatNumberDecimal, getGenericToken } from "../../scripts/helpers"
import { useEffect, useState } from "react"
import { getLocationsById } from "../../scripts/fetchApi"
import { getAuthToken } from "../../scripts/auth"
import AddToFavoriteOrders from "./AddToFavoriteOrders"
import DispatchOrderTracker from './DispatchOrderTracker'
import ClockIcon from "../../icons/clockicon"
import DeliveryIcon from "../../icons/deliveryicon"
import StoreIcon from "../../icons/storeicon"
import CurbsideIcon from "../../icons/curbsideicon"
import PhoneIcon from "../../icons/phoneicon"
import OrderIcon from "../../icons/ordericon"
import DetailsIcon from "../../icons/detailsicon"

const OrderStatusComponent = () => {
    const params = useParams()
    const [location, setLocation] = useState(null)

    // const token = getAuthToken()
    // const token = sessionStorage.getItem('token')
    const token = getGenericToken()

    const data = JSON.parse(sessionStorage.getItem(params.orderId))


    useEffect(() => {

        (async function() {
            const loc = await getLocationsById(data.vendorid)
 
            setLocation(loc)
        })();

    }, [data.vendorid])




    return <div className="container-full">
        {data && <div className={classes.wrapper}>
            {data.status !== "Completed" && <>
                
                {data.deliverymode === "pickup" && <div className={classes.intro}>
                    <h1 className={classes.page_header}>Pickup instructions</h1>
                    <div className={classes.page_text}>
                        <p>Go Straight to Order Pickup At the Counter & Tell a Crew Member You Ordered Online.</p>
                    </div>
                    <div className={classes.pickup_time}>
                        <div className={classes.pickup_time_label}>
                            <ClockIcon />
                            Your order will be ready for pickup at:
                        </div> 
                        <span>{formatAndSplitDates(data.readytime ? data.readytime : data.earliestreadytime)}</span>
                    </div>
                    <div className={classes.directions}>
                        {/* <a className="btn" href={`https://maps.google.com/?q=${data.location.address.street} ${data.location.address.city} ${data.location.address.state} ${data.location.address.zip} ${data.location.address.country}`} target="_blank" rel="noreferrer">Get Directions</a> */}

                    </div>
                </div>}
                {data.deliverymode === 'curbside' && <div className={classes.intro}>
                    <h1 className={classes.page_header}>Curbside Instructions</h1>
                    <div className={classes.page_text}>
                        <p>Your order has been confirmed. When you arrive, please let us know you're here so the store knows to bring your order.</p>
                    </div>
                </div>}
                {data.deliverymode === 'dispatch' && <div className={classes.intro}>
                    <h1 className={classes.page_header}>Order tracker</h1>
                    <DispatchOrderTracker />
                    <div className={classes.page_text}>
                        We have your order in our system and it's getting prepared!
                    </div>
                </div>}
            </>}
            <div className={classes.thank_you}>
                <h2 className={classes.page_header}>Thank You</h2>
                <div className={classes.page_text}>
                    <p>Thanks for your order! Visit our website to give us a shout and let us know what you think!</p>
                </div>
            </div>
            <div className={classes.details}>
                <div className={`${classes.details_item} ${classes.details_delivery}`}>
                    <div className={classes.details_header}>
                        <DeliveryIcon fill={'#8D0E57'} dimensions={{width: 37, height: 23}}/>
                        delivery details
                    </div>

                    <div className={classes.card_container}>
                        {data.deliverymode === "pickup" && <div className={classes.store_info_header}>
                            <StoreIcon />
                            Pickup from
                        </div>}
                        {data.deliverymode === "curbside" && <div className={classes.store_info_header}>
                            <CurbsideIcon />
                            Order Location
                        </div>}
                        {data.deliverymode === 'dispatch' && <div className={classes.store_info_header}>                            
                            <DeliveryIcon/>
                            delivering from
                        </div>}
                        
                        {location && <div className={classes.card}>
                            <div className={classes.card_item_simple}>
                                <div className={classes.location_name}>{data.vendorname}</div>
                                <div className={classes.location_address}>{location.address.street}</div>
                            </div>
                            
                            <div className={`${classes.card_item} ${classes.card_item_border}`}>
                                <div className={classes.card_item}><PhoneIcon dimensions={{width: 18, height: 18}}/> {location.telephone}</div>
                            </div>                            
                        </div>}
                        {data.deliverymode === 'dispatch' && <>
                            <div className={classes.store_info_header}>Deliver To</div>
                            <div className={classes.card}>
                                <p>{data.deliveryaddress.streetaddress}, {data.deliveryaddress.zipcode}, {data.deliveryaddress.city}</p>    
                            </div>
                        </>}
                    </div>
                </div>
                <div className={`${classes.details_item} ${classes.details_summary}`}>
                    <div className={classes.details_header}>
                        <OrderIcon fill={'#8D0E57'} dimensions={{width: 16, height: 21}}/>
                        order summary
                    </div>

                    <div className={classes.card_container}>
                        <div className={classes.order_contents}>
                            <div className={classes.store_info_header}>
                                <OrderIcon fill={'#584646'}/>
                                Contents
                            </div>
                            {data.products.map(item => (
                                <div key={item.id} className={classes.card}>
                                    {item.image && <div className={classes.image_container}>
                                        <img src={item.image} alt={item.title} />
                                    </div>}
                                    <div>
                                        <div className={classes.item_header}>                                        
                                            <div className={classes.item_title}>{item.name}</div>
                                            <div className={classes.item_price}>${formatNumberDecimal(item.totalcost)}</div>     
                                        </div>
                                        <div className={classes.item_description}>{item.choices.map(item => item.name).join(', ')}</div>
                                    </div>
                                </div>
                                
                            ))}
                        </div>

                        <div className={classes.aditional_information}>
                            <div className={classes.store_info_header}>
                                <DetailsIcon />
                                Additional details
                            </div>
                            <div className={classes.card}>
                                <div className={classes.card_item}>
                                    Order Number
                                    <span>{data.oloid}</span>
                                </div>
                                <div className={`${classes.card_item} ${classes.card_item_border}`}>
                                    Payment Method
                                    <span>Credit Card</span>
                                </div>

                                {data.deliverymode === "curbside" && <>
                                    <div className={`${classes.card_item} ${classes.card_item_border}`}>
                                        Make
                                        <span>{data.customfields[0].value}</span>
                                    </div>
                                    <div className={`${classes.card_item} ${classes.card_item_border}`}>
                                        Model
                                        <span>{data.customfields[1].value}</span>
                                    </div>
                                    <div className={`${classes.card_item} ${classes.card_item_border}`}>
                                        Color
                                        <span>{data.customfields[2].value}</span>
                                    </div>
                                </>}
                            </div>
                            <div className={classes.card}>
                                <div className={`${classes.card_item} ${classes.card_item_header}`}>
                                    Order total
                                    <span>${formatNumberDecimal(data.total)}</span>
                                </div>
                                <div className={`${classes.card_item} ${classes.card_item_border}`}>
                                    Subtotal
                                    <span>${formatNumberDecimal(data.subtotal)}</span>
                                </div>
                                {data.customerhandoffcharge !== 0 && <div className={classes.card_item}>
                                    Delivery Charge
                                    <span>${formatNumberDecimal(data.customerhandoffcharge)}</span>
                                </div>} 
                                {data.tip !== 0 && <div className={classes.card_item}>
                                    Tip
                                    <span>${formatNumberDecimal(data.tip)}</span>
                                </div>}                           
                                <div className={classes.card_item}>
                                    Tax
                                    <span>${formatNumberDecimal(data.taxes[0].tax)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>}

        {token && <div className={classes.order_page_footer}>
            <Link to="/account/recent" className="btn">See All Recent Orders</Link>
            <AddToFavoriteOrders orderID={data.id}/>
        </div>}
    </div>
}

export default OrderStatusComponent