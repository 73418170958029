import classes from './WarningComponent.module.css'
import WarningItem from "./WarningItem"


const WarningComponent = ({data}) => {
    return <>   
        <div className={classes.warning_container}>
            {data && data.length > 0 && data.map(error => (
                <WarningItem key={error.id} id={error.id} type={error.type}>
                    {(typeof error.message === "string") && error.message}
                    {/* {(typeof error.message !== "string") && 'Something went wrong'} */}
                    {(typeof error.message !== "string") && error.message.errors && <ul>
                        {Object.entries(error.message.errors).map(err => (
                            <li key={err[0]} >{err[0]} {err[1]}</li>
                        ))}    
                    </ul>}
                </WarningItem>
            ))}
        </div>
    </>
}

export default WarningComponent