import { useState } from "react"
import { formatOLODate } from "../../scripts/helpers"
import { getLocationsByLatLng, cartSetDeliveryMethod, validateCart } from "../../scripts/fetchApi"
import { useDispatch, useSelector } from "react-redux"
import { variousActions } from "../../store/various"
import { setSessionStorageItem, setStorageItem } from "../../scripts/localStorage"
import { cartActions } from "../../store/cart"

import AddressInput from "../locations/AddressInput"

import classes from './Cart.module.css'

const OrderTypeDispatchModalSimplified = ({closeModal}) => {
    const dispatch = useDispatch()
    const location = useSelector(state => state.location.location) 
    const cart = useSelector(state => state.cart.cart)
    const [inputError, setInputError] = useState({
        hasErrors: false,
        errorMessage: null
    })
    const [inputValue, setInputValue] = useState('')
    const [searchedLocations, setSearchedLocation] = useState('')
    const [loading, setLoading] = useState(false)

    const validateCartHandler = async (cartId) => {

        const validCart = await validateCart(cartId)

        if (validCart.id) {
            // setStorageItem('currentCart', validCart)
            setSessionStorageItem('currentCart', validCart)
            dispatch(cartActions.addCart(validCart))

            closeModal()
            setLoading(false)
            // return cart;
        } else {
            dispatch(variousActions.setError(validCart))
            setLoading(false)
            // return null
        }
    }
    const setErrorAddress = (data) => {

        if (data) {
            setInputError({
                hasErrors: true,
                errorMessage: data
            })      
        } else {
            setInputError({
                hasErrors: false,
                errorMessage: null
            })
        }
    }

    const submitFormHandler = (event) => {
        event.preventDefault()
        setLoading(true)
        if (searchedLocations && searchedLocations.full_address) {
            const inputAddress = searchedLocations.full_address; 
            
            const geocoder = new window.google.maps.Geocoder();
            let lat = "";
            let lng = "";
            const geo = async () => {

                await geocoder.geocode(
                    {
                        // address: "inputAddress " + inputAddress,
                        address: inputAddress,
                        componentRestrictions: {
                            country: "US",
                        },
                    },
                    function (results, status) {
                        
                        if (status == window.google.maps.GeocoderStatus.OK) {
                            lat = results[0].geometry.location.lat();
                            lng = results[0].geometry.location.lng();
                        } else {
                            // setNoLocation(true)
                            return;
                        }
                    }
                );

                return { lat: lat, lng: lng };
            };

            (async () => {
                var latLng = await geo();
                
                let loc = await getLocationsByLatLng(latLng, 'dispatch')
                if (loc.length > 0) {
                    
                    // setFoundLocations(loc)
                    const addressIsInRange = loc.find(item => item.id === location.id)
                    if (addressIsInRange) {
                        const deliveryCartInfo = {
                            delivery_mode: 'delivery',
                            time: cart.timemode === "advance" ? 'schedule' : cart.timemode,
                            schedule_date: cart.timewanted === null ? null : formatOLODate(cart.timewanted),
                            schedule_time: cart.timewanted === null ? null : cart.timewanted.split(" ")[1],
                            street_address: searchedLocations.street_number + ' ' + searchedLocations.street,
                            city: searchedLocations.city,
                            zip_code: searchedLocations.postal_code
                        } 

                        const cartDelivery = await cartSetDeliveryMethod(cart.id, deliveryCartInfo)
                        if (cartDelivery.id) {
                            //success

                            validateCartHandler(cartDelivery.id)
                        } else {
                            dispatch(variousActions.setError(cartDelivery))
                            setLoading(false)
                        }

                        
                    } else {
                        dispatch(variousActions.setError({message: 'Delivery currently not available to this address, please place a pickup order or try another location'}))
                        setLoading(false)
                    }
                    
                } else {
                    setLoading(false)
                    dispatch(variousActions.setError({message: 'Delivery currently not available to this address, please place a pickup order or try another location'}))
                    
                }
            })();
        }
        // setLoading(false)
    }
    return <div>
        <h3>Delivery Details</h3>
        <form onSubmit={submitFormHandler} className="form-fields-container">
            <div className="form-controller">
                <label className='label' htmlFor='delivery_address'>Enter your delivery address</label>
                {/* <input ref={streetRef} type="text" id="delivery_address" name="delivery_address" placeholder="enter your address" className={`${!formInputsValidity.address ? 'error' : null}`} required/> */}
                <AddressInput inputError={inputError.hasErrors} setError={setErrorAddress} setValue={(value) => {setInputValue(value)}} setAddressDetails={(data) => {setSearchedLocation(data)}}/>
                {/* {!formInputsValidity.address && <div className='error-message'>Please enter an address</div>} */}
            </div>
            {inputError.hasErrors && <div className='error-message'>{inputError.errorMessage}</div>}         
            <div className={`form-controller ${classes.modal_footer}`}>
                {!loading && <button type="submit" className="btn" disabled={inputError.hasErrors}>Set Your Address</button>}
                {loading && <div className="btn"><span className="loader"></span></div>}
            </div>
        </form>
    </div>
}

export default OrderTypeDispatchModalSimplified