import { useState, useEffect } from "react"
import AddressInput from "../locations/AddressInput"
import SearchIcon from "../../icons/searchicon"
import { getLocationsByLatLng } from "../../scripts/fetchApi"
import { useDispatch, useSelector } from "react-redux"
import { variousActions } from "../../store/various"

import classes from './DeliverySelection.module.css'

const DeliverySetAddress = ({selectedDate, selectedTime, setShowDetails, setSearchedLoc}) => {
    const dispatch = useDispatch()

    const location = useSelector(state => state.location.location) 

    const [inputValue, setInputValue] = useState('')
    const [searchedLocations, setSearchedLocation] = useState('')
    const [loading, setLoading] = useState(false)
    const [scheduled, setScheduled] = useState(false) 

    // const [selectedDate, setSelectedDate] = useState(null)
    // const [selectedTime, setSelectedTime] = useState(null)

    const [inputError, setInputError] = useState({
        hasErrors: false,
        errorMessage: null,
        disableButton: true
    })

    const setErrorAddress = (data) => {

        if (data) {
            setInputError({
                hasErrors: true,
                errorMessage: data,
                disableButton: true
            })      
        } else {
            setInputError({
                hasErrors: false,
                errorMessage: null,
                disableButton: false
            })
        }
    }


    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    useEffect(() => {
        if (selectedDate && selectedTime && !inputError.disableButton) {
            setIsButtonDisabled(false)
        } else {
            setIsButtonDisabled(true)
        }  
 
    }, [selectedDate, selectedTime, inputError.disableButton])


    const submitFormHandler = (event) => {
        event.preventDefault()
        setLoading(true)


        if (searchedLocations && searchedLocations.full_address) {
            setSearchedLoc(searchedLocations)
            const inputAddress = searchedLocations.full_address; 
            
            const geocoder = new window.google.maps.Geocoder();
            let lat = "";
            let lng = "";
            const geo = async () => {

                await geocoder.geocode(
                    {
                        // address: "inputAddress " + inputAddress,
                        address: inputAddress,
                        componentRestrictions: {
                            country: "US",
                        },
                    },
                    function (results, status) {
                        
                        if (status == window.google.maps.GeocoderStatus.OK) {
                            lat = results[0].geometry.location.lat();
                            lng = results[0].geometry.location.lng();
                        } else {
                            // setNoLocation(true)
                            return;
                        }
                    }
                );

                return { lat: lat, lng: lng };
            };

            (async () => {
                var latLng = await geo();
                
                let loc = await getLocationsByLatLng(latLng, 'dispatch')
                if (loc.length > 0) {
                    
                    // setFoundLocations(loc)
                    const addressIsInRange = loc.find(item => item.id === location.id)
                    if (addressIsInRange) {
                        setShowDetails(true)
                            
                    } else {
                        setShowDetails(false)
                        dispatch(variousActions.setError({message: 'Delivery currently not available to this address, please place a pickup order or try another location'}))
                        setLoading(false)
                    }
                    
                } else {
                    setShowDetails(false)
                    setLoading(false)
                    dispatch(variousActions.setError({message: 'Delivery currently not available to this address, please place a pickup order or try another location'}))
                    
                }
            })();
        }
        // setLoading(false)
    }


    return <>
        <div className="form-controller">
            <label className='label' htmlFor='delivery_address'>Enter your address</label>  
            <div className={classes.form_input_container}>
                <AddressInput inputError={inputError.hasErrors} setError={setErrorAddress} setValue={(value) => {setInputValue(value)}} setAddressDetails={(data) => {setSearchedLocation(data)}}/>
        
                <div className={classes.btn_container}>
                    {!loading && <>
                        {!scheduled && <button type="submit" className={classes.btn} disabled={inputError.disableButton} onClick={submitFormHandler}>
                            <SearchIcon />
                            <span className='sr-only'>Search location</span>    
                        </button>}
                        {scheduled && <button type="submit" className={classes.btn} disabled={isButtonDisabled} onClick={submitFormHandler}>
                            <SearchIcon />
                            <span className='sr-only'>Search location</span>    
                        </button>}
                    </>}
                    {loading && <div className={classes.btn}><span className="loader"></span></div>}
                </div>
            </div>                  
            
        </div>
        {inputError.hasErrors && <div className='error-message'>{inputError.errorMessage}</div>}   
    </>
}

export default DeliverySetAddress