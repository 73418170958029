import DeliveryPannel from './DeliveryPannel';
import PickupPannel from './PickupPannel';
import classes from './SearchLocation.module.css'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { locationActions } from '../../store/location';
import PickupIcon from '../../icons/pickupicon';
import DeliveryIcon from '../../icons/deliveryicon';

const SearchLocation = ({closeSidebar}) => {
    const dispatch = useDispatch()


    const [changePannel, setChangePannel] = useState({
        pickup: true,
        curbside: false,
        delivery: false
    });   

    const virtualDeliveryType = useSelector(state => state.location.virtualDeliveryType)

    useEffect(() => {
        switch (virtualDeliveryType) {
            case 'curbside':
                setChangePannel({
                    pickup: false,
                    curbside: true,
                    delivery: false
                })
                break;
            case 'delivery':
                setChangePannel({
                    pickup: false,
                    curbside: false,
                    delivery: true
                })
                break;
            default:
                setChangePannel({
                    pickup: true,
                    curbside: false,
                    delivery: false
                })
                break;
        }
    }, [virtualDeliveryType])

    
    

    const pickupPannelHandler = () => {
        setChangePannel({
            pickup: true,
            curbside: false,
            delivery: false
        })
        dispatch(locationActions.setVirtualDeliveryType('pickup'))
        dispatch(locationActions.setOrderDate(null))
        dispatch(locationActions.setOrderTime(null))
        dispatch(locationActions.setOrderType('asap'))
    }

    const deliveryPannelHandler = () => {
        setChangePannel({
            pickup: false,
            curbside: false,
            delivery: true
        })
        dispatch(locationActions.setVirtualDeliveryType('delivery'))
        dispatch(locationActions.setOrderDate(null))
        dispatch(locationActions.setOrderTime(null))
        dispatch(locationActions.setOrderType('asap'))
    }

    return <>
        <div className={classes.sidebar_header}>
            <button className={`btn ${!changePannel.pickup ? 'btn-border' : ''}`} onClick={pickupPannelHandler}>
                <PickupIcon />
                <span>Order Pickup</span>
            </button> 
            <button className={`btn ${!changePannel.delivery ? 'btn-border' : ''}`} onClick={deliveryPannelHandler}>     
                <DeliveryIcon />
                <span>Order Delivery</span>
            </button>
        </div>
        <div className={classes.sidebar_content}>
            {changePannel.pickup && <div className={classes.sidebar_content_inner}>
                <PickupPannel />
            </div>}
            {changePannel.delivery && <div className={classes.sidebar_content_inner}>
                <DeliveryPannel />
            </div>}
        </div>
    </>
}

export default SearchLocation;