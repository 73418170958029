import { useState } from 'react';
import classes from './Authentication.module.css'
import Signin from './Signin';
import Signup from './Signup';

const Authentication = ({closeSidebar}) => {
    const [showSignInPannel, setShowSignInPannel] = useState(true);
    const [showSignUnPannel, setShowSignUnPannel] = useState(false);

    const showSignInPannelHandler = () => {
        setShowSignInPannel(true)
        setShowSignUnPannel(false)
    }

    const showSignUpPannelHandler = () => {
        setShowSignInPannel(false)
        setShowSignUnPannel(true)
    }
    return <>
        <div className={classes.sidebar_header}>
            <button className={`btn ${!showSignInPannel ? 'btn-border' : ''}`} onClick={showSignInPannelHandler}>Sign In</button>
            <button className={`btn ${!showSignUnPannel ? 'btn-border' : ''}`} onClick={showSignUpPannelHandler}>Join Rewards</button>
        </div>

        <div className={classes.sidebar_content}>
            <div className={classes.sidebar_content_inner}>
                {showSignInPannel && <Signin closeSidebar={closeSidebar}/>}
                {showSignUnPannel && <Signup />}
            </div>
        </div>
    </>
}

export default Authentication;