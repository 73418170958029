import { useParams } from "react-router-dom"
import ProductCard from "./ProductCard";
import SubCategories from "./SubCategories";
import SubCategoryHeader from "./SubCategoryHeader";
import CategoriesHeader from "./CategoriesHeader";
import { useSetMenu } from "../../hooks/hooks";
import classes from './Category.module.css'
import { useEffect } from "react";

const Category = () => {
    const params = useParams();

    const menu = useSetMenu()

    const item = menu.categories.find(item => item.slug == params.categoryId)

    useEffect(() => {
        // START GTM DATA
        if  (item) {
                
            let prodArray = []
            if (item.products) {
                item.products.forEach(element => {
                    prodArray.push({
                        item_id: element.id,
                        item_name: element.name,
                        price: element.cost, // e.g. 9.99,
                        quantity: 1, // e.g. 1
                        item_list_id: item.id,
                        item_list_name: item.name,
                    })
                });
            }
            if (item.sub_categories) {
                item.sub_categories.forEach(sub => {
                    if (sub.products) {
                        sub.products.forEach(element => {
                            prodArray.push({
                                item_id: element.id,
                                item_name: element.name,
                                price: element.cost, // e.g. 9.99,
                                quantity: 1, // e.g. 1
                                item_category: sub.name,
                                item_list_id: item.id,
                                item_list_name: item.name,
                            })
                        })
                    }
                })           
            }       
            window.dataLayer = window.dataLayer || [];  
            window.dataLayer.push({
                event: "view_item_list",
                ecommerce: {
                    item_list_id: item.id,
                    item_list_name: item.name,
                    items: prodArray
                }
            });
                
            
        }
        // END GTM DATA
    }, [])

 
    return <>
        <CategoriesHeader />
        {item && <div className={classes.section}>
            <div className="container-full">
                <h1>{item.name}</h1>
                {/* category has only products  */}
                {item.products && <div className='grid-3'>
                    {item.products.map(item => (
                        <ProductCard key={item.id} data={item} />
                    ))}
                </div>} 
                

                {/* category has subcategories  */}
                {item.sub_categories && item.sub_categories.length > 0 && <div > 
                    {/* only show header items if there are more than 3  */}
                    {item.sub_categories.length > 3 && <SubCategoryHeader item={item}/>}    
                    
                    {item.sub_categories.filter(item => (item.products && item.products.length > 0) || (item.sub_categories && item.sub_categories.length > 0)).map((item, mainCatIndex) => (

                        <div key={item.id} className={`${classes.sub_categories_item} sub_categories_item`} id={`item-${item.id}`}>
                            <h3 className={classes.sub_title}>
                                {/* <span className={classes.icon} dangerouslySetInnerHTML={{__html: item.icon}}></span> */}
                                {item.image && <span className={classes.icon}>
                                    <img src={item.image} alt="icon"/>
                                </span>}
                                {item.name}
                            </h3>
                            <div className={classes.sub_description}>{item.description}</div>

                            {/* sub categorie has again a sub categorie  */}
                            {item.sub_categories && item.sub_categories.length > 0 && <div className={classes.sub_cat_sub_cat}>
                                <SubCategories data={item.sub_categories} />
                            </div>}


                            {/* simple products  */}
                            {item.products && item.products.length > 0 && <div className={classes.sub_cat_sub_cat}>
                                <div className={[classes.items_container, "grid-3"].join(' ')}>
                                    {item.products.map(item => (
                                        <ProductCard key={item.id} data={item} />
                                    ))}
                                </div>                                
                            </div>}                            
                            
                        </div>
                        
                    ))}
                </div>}
            </div>
            
        </div>}
    </>
}

export default Category