import classes from './BannerSimple.module.css'

import imageLeftCNC from '../../assets/banner/MSC_Ice_Cream.webp'
import imageRightCNC from '../../assets/banner/GAC_Cake.webp'
// import imageLeftCNC from '../../assets/banner/GAC_Cookies.webp'

import imageLeft from '../../assets/banner/GAC_Cookies.webp'
import imageRight from '../../assets/banner/GAC_Cake.webp'

import imageLeftMSC from '../../assets/banner/MSC_Ice_Cream.webp'
import imageRightMSC from '../../assets/banner/MSC_Cake.webp'


import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getItemFromStorage } from '../../scripts/localStorage'
import { isMSCChangeStyleStore } from '../../scripts/helpers'

const BannerSimple = () => {

    let locationStore = useSelector(state => state.location.location)

    const [mscLocation, setMscLocation] = useState(false)
    let location = useSelector(state => state.location.location) 
    const storage = getItemFromStorage('currentLocation') 
    useEffect(() => {
        if (storage && storage.currentLocation && storage.currentLocation.data && storage.currentLocation.data.attributes && storage.currentLocation.data.attributes.includes("MSC")) {
            setMscLocation(true)
        } else {
            setMscLocation(false)
        }
        // if (location && location.data && location.data.attributes && location.data.attributes.includes("MSC")) {

        // }
        // isMSCChangeStyleStore(location)
    }, [storage])


    return <div className={`${classes.banner} ${mscLocation ? classes.banner_msc : ''}`} >
        {/* style={{backgroundImage: `url("data:image/svg+xml,%3Csvg%20width%3D%221024%22%20height%3D%2297%22%20viewBox%3D%220%200%201024%2097%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M193.096%2070.5399C159.857%2062.6559%20120.442%2054.7719%2071.4344%2074.5489C45.5329%2085.0009%2022.7976%2085.0005%200.439148%2085H-0.00012207L0%200H29.5H1024V85C990.239%2077.7%20951.342%2068.5109%20931.464%2076.6499C880.763%2097.3559%20779.054%2098.2529%20722.237%2071.8029C693.046%2058.2269%20654.494%2069.7149%20611.965%2081.2029C571.632%2092.0939%20527.713%20102.989%20484.816%2092.5029C396.741%2070.9579%20303.841%2063.6569%20258.582%2076.7029C239.247%2082.3009%20217.89%2076.4209%20193.096%2070.5399Z%22%20fill%3D%22%23${options.bk_color?.split('#').pop()}%22%2F%3E%0A%3Cpath%20d%3D%22M193.096%2070.5399C159.857%2062.6559%20120.442%2054.7719%2071.4344%2074.5489C45.533%2085.0009%2022.7977%2085.0005%200.439148%2085L-0.00012207%2085L0%2075C22.4999%2075%2045.3633%2074.9476%2071.4344%2064.4271C120.442%2044.6501%20159.857%2052.5342%20193.096%2060.4182C217.89%2066.2992%20239.247%2072.1791%20258.582%2066.5811C303.841%2053.5351%20396.741%2060.8361%20484.816%2082.3811C527.713%2092.8671%20571.632%2081.9721%20611.965%2071.0811C654.494%2059.5931%20693.046%2048.1052%20722.237%2061.6812C779.054%2088.1312%20880.763%2087.2341%20931.464%2066.5281C951.342%2058.3891%20990.239%2067.7%201024%2075V85C990.239%2077.7%20951.342%2068.5109%20931.464%2076.6499C880.763%2097.3559%20779.054%2098.2529%20722.237%2071.8029C693.046%2058.2269%20654.494%2069.7149%20611.965%2081.2029C571.632%2092.0939%20527.713%20102.989%20484.816%2092.5029C396.741%2070.9579%20303.841%2063.6569%20258.582%2076.7029C239.247%2082.3009%20217.89%2076.4209%20193.096%2070.5399Z%22%20fill%3D%22%23AA0061%22%2F%3E%0A%3C%2Fsvg%3E")`}} */}
        <div className={`${classes.banner_inner_wrapper} ${(locationStore && locationStore.cobranded) ? classes.banner_inner_wrapper_cobrand : ''}`}>
            <div className={`${classes.graphic_container} ${classes.graphic_container_left} ${(locationStore && locationStore.data.attributes.includes("MSC")) ? classes.graphic_container_msc : null}`}>
                {locationStore && <>
                    {locationStore.cobranded && <img src={imageLeftCNC} alt='banner' className={`${classes.side_image} image-contain`} />}
                    {!locationStore.cobranded && locationStore.data.attributes && locationStore.data.attributes.includes("MSC") && <img src={imageLeftMSC} alt='banner' className={`${classes.side_image} ${classes.side_image_msc} image-contain`} />}
                </>}
                {(!locationStore || (locationStore && !locationStore.cobranded && !locationStore.data.attributes.includes("MSC"))) && <img src={imageLeft} alt='banner' className={`${classes.side_image} image-contain`} />}
            </div>
            <div className={classes.banner_inner}>
                
                <h1 className={classes.title}>Menu</h1>
                
            </div>
            <div className={`${classes.graphic_container} ${classes.graphic_container_right} ${(locationStore && locationStore.data.attributes.includes("MSC")) ? classes.graphic_container_msc : null}`}>
                {locationStore && <>
                    {locationStore.cobranded && <img src={imageRightCNC} alt='banner' className={`${classes.side_image} image-contain`} />}
                    {!locationStore.cobranded && locationStore.data.attributes && locationStore.data.attributes.includes("MSC") && <img src={imageRightMSC} alt='banner' className={`${classes.side_image} ${classes.side_image_msc} image-contain`} />}
                </>}
                {(!locationStore || (locationStore && !locationStore.cobranded && !locationStore.data.attributes.includes("MSC"))) && <img src={imageRight} alt='banner' className={`${classes.side_image} image-contain`} />}
            </div>
        </div>
        <div className={classes.banner_bottom_line}></div>
    </div>
}

export default BannerSimple