import { useEffect, useRef, useState } from 'react';
import clasess from './Sidebar.module.css'
import CloseIcon from '../../icons/closeicon';
import { CSSTransition } from 'react-transition-group';

const Sidebar = ({click, children, activeState}) => {
    const [transition, setTransition] = useState(false)
    const nodeRef = useRef(null)
    useEffect(() => {
        setTimeout(() => {
            setTransition('active')
        }, 100);
    })

    return <CSSTransition 
        in={activeState} 
        nodeRef={nodeRef} 
        timeout={400}
        classNames="sidebar-group"
        unmountOnExit>
        <div className={`${clasess.sidebar}`} ref={nodeRef}>
            <button onClick={click}  className={[clasess.close_button, "btn-clear"].join(' ')}>
                <CloseIcon />
                <span className='sr-only'>close sidebar</span>
            </button>
            {children}
        </div>
    </CSSTransition>
}

export default Sidebar;